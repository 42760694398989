<template>
  <div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <header class="page-header">
          <el-row :gutter="20" align="middle" type="flex">
            <el-col :span="12">
              <router-link to="/return" class="mr20">
                <el-button type="default"
                  ><i class="el-icon-back"></i
                ></el-button>
              </router-link>
              <h2 class="inline-block">{{transfer.transfer_id}}</h2>
              <el-tag :type="transfer.transfer_status === 'complete' ? 'success' : 'warning'" class="ml20" effect="dark">{{transfer.transfer_status}}</el-tag>
            </el-col>
          </el-row>
        </header>
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="panel">
              <div class="panel-item">
                <header>Бүтээгдэхүүнүүд</header>
                <ul class="transfer-product-list">
                  <div v-for="(product) in transfer.products" :key="product.productId">
                    <li v-if="product.variants.length > 0">
                      <el-row :gutter="10" type="flex" align="middle">
                        <el-col :span="2">
                          <div class="image-holder">
                            <img :src="product.product_image" alt="">
                          </div>
                        </el-col>
                        <el-col :span="22">
                          {{product.product_name}}
                        </el-col>
                      </el-row>
                      <ul>
                        <li v-for="(variant) in product.variants" :key="variant.variant_id">
                          <el-row :gutter="20" type="flex" align="middle">
                            <el-col :span="16" :offset="2">
                              <div>
                                {{variant.variant_name}}
                              </div>
                              <div class="text-color-secondary">
                                {{variant.variant_sku}}
                              </div>
                            </el-col>
                            <el-col :span="6">
                              <div class="text-center">
                                <progress-bar
                                :accepted="variant.accepted"
                                :canceled="variant.canceled"
                                :rejected="variant.rejected"
                                :total="variant.number_tobe_transferred + variant.accepted + variant.canceled + variant.rejected"
                                ></progress-bar>
                              </div>
                            </el-col>
                          </el-row>
                        </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </div>
              <div class="panel-item" v-if="transfer.transfer_status !== 'complete'">
                <div class="text-right">
                  <el-button type="default" @click="visibleFinishTransfer = true">Буцаалтыг дуусгах</el-button>
                  <el-button type="success" @click="$router.push({name: 'receiveReturnDetail', params: { transfer_id: transfer.transfer_id }})">Барааг хүлээж авах</el-button>
                </div>
              </div>
            </div>
            <changes :changes="transfer.changes"></changes>
          </el-col>
          <!-- right sidebar -->
          <el-col :span="8">
            <div class="panel">
              <div class="panel-item">
                <header>Нийлүүлэгч байгууллага</header>
                <div v-if="transfer.supplier">
                  <div>
                    <strong class="uppercase">{{
                      transfer.supplier.supplier_monName
                    }}</strong>
                  </div>
                  <p>
                    {{transfer.supplier.address}}
                  </p>
                  <p>{{ transfer.supplier.phone }}</p>
                  <p>info@goyo.mn</p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-item">
                <header>Хүлээн авах огноо</header>
                <div>{{transfer.expected_arrival_at}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div v-else class="text-center mtb50 text-color-secondary">
      {{pageStatus.message}}
    </div>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/return">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- Delete confirmation -->
    <el-dialog
      title="Буцаалтыг устгах"
      :visible.sync="confirmDeleteDialog"
      width="50%"
    >
      <div>
        <strong>{{transfer.transfer_id}}</strong> дугаартай буцаалтыг усгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDeleteDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteTransfer(transfer.transfer_id)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
    <!-- Finish transfer dialog -->
    <finish-dialog
      v-if="visibleFinishTransfer"
      :visible-finish-transfer.sync="visibleFinishTransfer"
      :transfer="transfer">
    </finish-dialog>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import FinishDialog from './components/finishDialog.vue'
import Changes from './components/changes.vue'
import ProgressBar from './components/progressBar.vue'
import { getUserName } from '../../../utils/auth.js'
export default {
  name: 'receiveTransfer',
  components: {
    FinishDialog,
    Changes,
    ProgressBar
  },
  data () {
    return {
      visibleFinishTransfer: false,
      confirmDeleteDialog: false,
      errorArray: [],
      searchText: '',
      confirmLeaveForm: false,
      loading: true,
      isFormFetched: false,
      pageStatus: {
        error: false,
        message: ''
      },
      transfer: {
        products: [],
        warehouse_name: '',
        expected_arrival_at: null,
        supplier_id: null,
        created_at: null,
        transfer_id: null,
        transfer_name: '',
        changes: [],
        transfer_status: '',
        supplier: {}
      }
    }
  },
  created () {
    if (this.$route.params.transfer_id) {
      this.getTransfer(this.$route.params.transfer_id)
    }
  },
  methods: {
    getTransfer (id) {
      services.getOneReturn(id).then(response => {
        if (response.status === 'success') {
          this.transfer = response.data
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    async saveTransfer () {
      const sendBody = {
        products: [],
        warehouse_name: this.transfer.warehouse_name,
        expected_arrival_at: this.transfer.expected_arrival_at,
        supplier_id: this.transfer.supplier_id,
        transfer_id: this.transfer.transfer_id,
        transfer_name: this.transfer.transfer_name,
        transfer_status: this.transfer.transfer_status,
        user_name: ''
      }
      sendBody.user_name = await getUserName()
      const productsBody = []
      const variantsBody = []
      this.errorArray = []
      if (this.transfer.products.length === 0) {
        this.errorArray.push(
          {
            key: '',
            value: 'Бүтээгдэхүүн сонгоно уу'
          }
        )
      }
      this.transfer.products.forEach(product => {
        product.variants.forEach(variant => {
          if (variant.number_tobe_transferred < 1) {
            this.errorArray.push(
              {
                key: variant.sku,
                value: `${variant.sku} дугаартай барааны татан авах ширхэг 1 ээс их байх ёстой`
              }
            )
          }
        })
      })
      if (this.errorArray.length === 0) {
        this.loading = true
        this.transfer.products.forEach(product => {
          product.variants.forEach(variant => {
            variantsBody.push({
              product_id: product.productId,
              number_tobe_transferred: variant.number_tobe_transferred,
              variant_id: variant.variant_id,
              variant_sku: variant.variant_sku
            })
            productsBody.push({
              productId: product.productId,
              variants: variantsBody
            })
          })
        })
        sendBody.products = productsBody
        services.changeTransfer(sendBody).then(data => {
          if (data.status === 'success') {
            this.alertReporter('Амжилттай', data.message, 'success')
            setTimeout(() => {
              this.$router.push({ name: 'transfer' })
              this.loading = false
            }, 1000)
          } else if (data.status === 'error') {
            this.alertReporter('Алдаа', data.message, 'error')
            this.loading = false
          }
        })
      }
    }
  }
}
</script>
<style>
.el-input-group__prepend {
  background-color: #ffffff;
}
.el-table::before {
  content: unset;
}
.el-table tr:last-child td {
  border-bottom: none;
}
</style>
