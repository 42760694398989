<template>
  <div class="">
    <h3 class="ml40">Түүх</h3>
    <el-timeline>
      <el-timeline-item v-for="(change, index) in changes" :key="index" :timestamp="change.created_at" placement="top">
        <el-card>
          <h4 class="mt0">{{change.user_name}}</h4>
          <div class="mb10" v-for="(item, index) in change.log" :key="index">
            <el-row :gutter="20">
              <el-col :span="12">
                {{item.product_name}} - {{item.variant_name}}
              </el-col>
              <el-col :span="12">
                <el-row :gutter="5" class="text-right">
                  <el-col :span="8">
                    accepted : {{item.accepted}}
                  </el-col>
                  <el-col :span="8">
                    canceled : {{item.canceled}}
                  </el-col>
                  <el-col :span="8">
                    rejected : {{item.rejected}}
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script>
export default {
  name: 'returnChanges',
  props: ['changes']
}
</script>
